export default [
    {
        image: "/static/images/logo/first-slide.jpg",
        subHeading: "Big Sale",
        heading: "shop from anywhere",
        link: "SHOP NOW",
        routeSubTitle:""
    },
    {
        image: "/static/images/logo/second-slide.jpg",
        subHeading: "at discount",
        heading: "Buy now",
        link: "SHOP NOW",
        routeSubTitle:""
    },

]